const config = {
  apiKey: "AIzaSyDUiwqz1GD3XEbGnhjc6WizsDeiRGmUdu8",
  authDomain: "manageyourclub-973b2.firebaseapp.com",
  projectId: "manageyourclub-973b2",
  storageBucket: "manageyourclub-973b2.appspot.com",
  messagingSenderId: "1010568760739",
  appId: "1:1010568760739:web:fc75d4a25be1332d189e16",
  measurementId: "G-S1YWETF3XM",
};

export default config;
