import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../context/CartContext";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import Delete from "@mui/icons-material/Delete";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import ShoppingCartCheckout from "@mui/icons-material/ShoppingCartCheckout";

import { Cart } from "./Cart";
import { useNavigate } from "react-router-dom";

export const CartButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { getNumberOfItemsInCart, clearCart } = useContext(CartContext);
  const numberOfItemsInCart = getNumberOfItemsInCart();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (numberOfItemsInCart === 0) {
      handleClose();
    }
  }, [numberOfItemsInCart]);

  const goToCheckout = () => {
    handleClose();
    navigate("/checkout");
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Shopping Cart">
          <IconButton
            onClick={handleClick}
            size="large"
            aria-label="cart"
            aria-controls={open ? "shopping-cart" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Badge badgeContent={numberOfItemsInCart} color="secondary">
              <ShoppingCart sx={{ fontSize: 30, color: "#FFFFFF" }} />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              minWidth: 400,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Cart />
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {numberOfItemsInCart ? (
            <Button onClick={clearCart} variant="outlined" color="secondary">
              <Delete /> Clear Cart
            </Button>
          ) : (
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          )}
          <Button
            onClick={goToCheckout}
            variant="contained"
            color="primary"
            disabled={!numberOfItemsInCart}
            sx={{ ml: 8 }}
          >
            <ShoppingCartCheckout /> Checkout
          </Button>
        </DialogActions>
      </Menu>
    </>
  );
};
