import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const NotFound = () => (
  <Box sx={{ pt: 4 }}>
    <Typography color="error" variant="h3" gutterBottom>
      This Page Was Not Found
    </Typography>
    <Typography variant="body1" gutterBottom>
      Please check the URL or click the link below to return to the home page.
    </Typography>
    <Link href="/">Home</Link>
  </Box>
);
