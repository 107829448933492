import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Paper, Typography } from "@mui/material";
import { PendingPass } from "./pass/PendingPass";
import { ActivePass } from "./pass/ActivePass";

const PassWrapper = ({ children }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        mb: 1,
        width: "100%",
      }}
    >
      {children}
    </Paper>
  );
};

export const AvailablePass = () => {
  const { memberPasses } = useContext(AppContext);

  if (!memberPasses) {
    return null;
  }
  const activePass = memberPasses.find((pass) => pass.status === "active");
  const pendingPass = memberPasses.find((pass) => pass.status === "pending");
  if (activePass) {
    return (
      <PassWrapper>
        <ActivePass pass={activePass} />
      </PassWrapper>
    );
  }
  if (pendingPass) {
    return (
      <PassWrapper>
        <PendingPass pass={pendingPass} />
      </PassWrapper>
    );
  }
  return (
    <Typography color="error" variant="subtitle1">
      You have no passes available.
    </Typography>
  );
};
