import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Card, CardContent, Link, Typography, Box } from "@mui/material";

const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
};

export const ClubLocation = () => {
  const { clubInfo } = useContext(AppContext);

  if (!clubInfo) {
    return null;
  }
  return (
    <Card sx={{ display: "flex", width: "100%", mb: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography variant="h6" gutterBottom>
            {clubInfo.address.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {clubInfo.address.address1}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {clubInfo.address.city}, {clubInfo.address.us_state}{" "}
            {clubInfo.address.postal_code}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <Link href={`tel:${clubInfo.address.phone}`}>
              {formatPhoneNumber(clubInfo.address.phone)}
            </Link>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <Link href={`mailto:${clubInfo.address.email}`}>
              {clubInfo.address.email}
            </Link>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <Link href={clubInfo.address.website} target="_blank">
              {clubInfo.address.website}
            </Link>
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};
