import { Typography, Box, Grid } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { ProductCard } from "./ProductCard";
export const Products = () => {
  const { products } = useContext(AppContext);

  if (!products) {
    return null;
  }
  const productCategories = Object.keys(products).sort();

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Products
      </Typography>
      {productCategories.map((category) => (
        <Box
          key={category}
          sx={{
            mb: 2,
            border: "1px solid ",
            borderColor: "primary.main",
            borderRadius: "10px",
            borderTop: "none",
            boxShadow: 1,
          }}
        >
          <Box
            sx={{
              backgroundColor: "primary.main",
              borderRadius: "10px 10px 0 0",
              px: 2,
              py: 1,
              color: "white",
            }}
          >
            <Typography variant="h5">{category}</Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              {products[category].map((product) => (
                <Grid item key={product.id} xs={12} sm={6}>
                  <ProductCard product={product} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      ))}
    </>
  );
};
