import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Snackbar,
} from "@mui/material";
import { Loading } from "./Loading";
import { checkin, updateProfile } from "../services/firebase";
import { AppContext } from "../context/AppContext";
import { Waiver } from "./Waiver";

export const CheckInButton = ({ pass }) => {
  const { member, onCheckIn, isCheckedIn } = useContext(AppContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [waiverOpen, setWaiverOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const signReleaseWaiver = async () => {
    updateProfile(member, { signedReleaseWaiver: true });
    setWaiverOpen(false);
    createCheckin();
  };

  const handleCheckin = async () => {
    if (!member.signedReleaseWaiver) {
      setWaiverOpen(true);
    } else {
      createCheckin();
    }
  };

  const createCheckin = async () => {
    setError(null);
    setLoading(true);
    const checkedInResponse = await checkin(pass.id);
    setLoading(false);
    if (checkedInResponse.error === true) {
      setError("There was an error checking you in, please see the host.");
    } else {
      setSnackbarOpen(true);
      onCheckIn();
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ px: 2, py: 2 }}>
      <Dialog open={waiverOpen} onClose={() => setWaiverOpen(false)}>
        <Box>
          <DialogTitle>Release Waiver</DialogTitle>
          <DialogContent
            sx={{
              height: "30vh",
              overflowY: "scroll",
            }}
          >
            <Waiver />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setWaiverOpen(false)}>Cancel</Button>
            <Button onClick={signReleaseWaiver}>Accept</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="You have successfully checked in!"
      />

      {error && (
        <Typography variant="h6" color="error" gutterBottom>
          Error: {error}
        </Typography>
      )}
      <Loading
        isLoading={loading}
        message="Checking you in..."
        messages={[
          "Validating pass...",
          "Rolling out the welcome wagon...",
          "Considering our options...",
          "Checking vibes...",
          "Consulting the robots...",
          "Almost done...",
          "Checking you in...",
        ]}
      />
      <Button
        variant="contained"
        fullWidth
        disabled={isCheckedIn()}
        onClick={handleCheckin}
      >
        {isCheckedIn() ? "Checked In" : "Check In"}
      </Button>
    </Box>
  );
};
