import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import {
  Paper,
  Typography,
  Box,
  ButtonBase,
  Grid,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AvailablePass } from "./AvailablePass";
import { timeToDateString } from "../services/utils";

export const MemberCard = () => {
  const { member } = useContext(AppContext);
  const navigate = useNavigate();
  if (!member) {
    return null;
  }
  return (
    <Paper
      sx={{
        mt: 1,
        mb: 2,
      }}
      spacing={2}
      elevation={6}
    >
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={12} md={6}>
          <ButtonBase onClick={() => navigate("/profile")}>
            <Avatar
              src={member.photoURL}
              alt={member.displayName}
              sx={{
                height: 75,
                width: 75,
              }}
            />

            <Box sx={{ ml: 2, textAlign: "left" }}>
              <Typography variant="h6">{member.displayName}</Typography>
              <Typography variant="subtitle1" gutterBottom>
                Member Since: {timeToDateString(member.createdate)}
              </Typography>
            </Box>
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <AvailablePass />
        </Grid>
      </Grid>
    </Paper>
  );
};
