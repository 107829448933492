import { Box, Typography, Link, List, ListItem } from "@mui/material";

const style = {
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const PrivacyPolicy = () => {
  return (
    <>
      <Box sx={style}>
        <Box sx={{ py: 4, px: 2 }}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            We Value Your Privacy
          </Typography>
          <Typography variant="caption" gutterBottom>
            Last Updated: 12 September 2023
          </Typography>
        </Box>
        <Typography id="modal-modal-description" variant="body1" gutterBottom>
          Welcome to ManageYour.Club. We respect your privacy and are committed
          to protecting your personal information. This Privacy Policy will
          inform you about how we look after your data and tell you about your
          privacy rights.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Data We Collect
        </Typography>
        <Typography variant="h6" gutterBottom>
          Personal Data
        </Typography>
        <Typography variant="body1" gutterBottom>
          When you create or sign in to an account using Google Firebase
          authentication, we collect:
        </Typography>
        <List>
          <ListItem>Full name (if provided)</ListItem>
          <ListItem>Email address</ListItem>
          <ListItem>Profile picture (if provided)</ListItem>
        </List>
        <Typography variant="h6" gutterBottom>
          Transaction Data
        </Typography>
        <Typography>
          When you select a pass to purchase and enter your payment details:
        </Typography>
        <List>
          <ListItem>Purchase History</ListItem>
          <ListItem>Payment Method</ListItem>
        </List>
        <Typography variant="h6">
          Technical Data Via Google Analytics:
        </Typography>
        <List>
          <ListItem>IP address </ListItem>
          <ListItem> Browser type and version </ListItem>
          <ListItem> Time zone setting and location</ListItem>
          <ListItem> Browser plug-in types and versions </ListItem>
          <ListItem> Operating system and platform</ListItem>
          <ListItem>
            Other technology on the devices you use to access our platform
          </ListItem>
        </List>
        <Typography variant="h6" gutterBottom>
          How We Use Your Data
        </Typography>
        <List gutterBottom>
          <ListItem>To manage your account and provide our services.</ListItem>
          <ListItem>
            To process payments and maintain a record of transactions.
          </ListItem>
          <ListItem>
            For internal analysis and statistics to improve our services.
          </ListItem>
        </List>
        <Typography variant="h6" gutterBottom>
          Data Retention
        </Typography>
        <Typography gutterBottom>
          We will only retain your personal data for as long as necessary to
          fulfill the purposes we collected it for, including for satisfying any
          legal, accounting, or reporting requirements.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Third Parties
        </Typography>
        <Typography gutterBottom>
          We do not sell, trade, or transfer your data to external parties.
          However, we use third-party platforms like Google Firebase and Square.
          These third-party sites have separate and independent privacy
          policies. We, therefore, have no responsibility or liability for the
          content and activities of these linked sites.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Your Data Protection Rights
        </Typography>
        <Typography gutterBottom>
          Depending on your region, you might have specific rights concerning
          your personal data, such as accessing, correcting, or even deleting
          your data.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Security
        </Typography>
        <Typography gutterBottom>
          We prioritize protecting your data and have implemented security
          measures to ensure your personal data's safety.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Cookies
        </Typography>
        <Typography gutterBottom>
          We use cookies to aggregate data about site traffic and site
          interactions through Google Analytics. This allows us to offer better
          site experiences in the future.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Changes to This Policy
        </Typography>
        <Typography gutterBottom>
          Any changes made to this Privacy Policy in the future will be posted
          on this page, with an updated "Last Modified" date.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography>
          For any questions related to this Privacy Policy, please contact us
          at:{" "}
          <Link href="mailto:privacy@manageyour.club">
            privacy@manageyour.club
          </Link>
        </Typography>
      </Box>
    </>
  );
};
