import { Box, Typography } from "@mui/material";
import { SharePassButton } from "../SharePassButton";
import { CheckInButton } from "../CheckInButton";
export const PendingPass = ({ pass }) => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="overline">{pass.type} Pass</Typography>
      <Typography variant="subtitle1">Status: New</Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <SharePassButton pass={pass} />
        <CheckInButton pass={pass} />
      </Box>
    </Box>
  );
};
