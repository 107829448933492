import React, { useState, useContext } from "react";
import { AppContext } from "../context/AppContext";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";

export const ShippingAddressForm = ({
  setShippingAddress,
  initialAddress,
  cancel,
}) => {
  const { member } = useContext(AppContext);
  const [shippingInfo, setShippingInfo] = useState({
    name: member?.displayName || "",
    country: "US",
    ...initialAddress,
  });

  const handleChange = (event) => {
    setShippingInfo({
      ...shippingInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await setShippingAddress(shippingInfo);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h6">Shipping Address</Typography>
      <Box sx={{ px: 2 }}>
        <TextField
          required
          fullWidth
          name="name"
          label="Full Name"
          value={shippingInfo.name}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          required
          fullWidth
          name="address"
          label="Address"
          value={shippingInfo.address}
          onChange={handleChange}
          margin="normal"
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              required
              fullWidth
              name="city"
              label="City"
              value={shippingInfo.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <TextField
              required
              fullWidth
              name="zip"
              label="Zip / Postal code"
              value={shippingInfo.zip}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <TextField
              required
              fullWidth
              name="state"
              label="State/Province/Region"
              value={shippingInfo.state}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                required
                labelId="country-label"
                id="country"
                name="country"
                value={shippingInfo.country}
                onChange={handleChange}
              >
                <MenuItem value={"US"}>United States</MenuItem>
                <MenuItem value={"CA"}>Canada</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" sx={{ mr: 2 }} onClick={cancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Use This Address
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
