import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

export function AvatarGradient({ src, ...props }) {
  const color = "linear-gradient(to right, #833ab4, #fd1d1d, #fcb045)";
  const size = 35;
  const gap = 2;
  const gapColor = "warning.main";
  const thickness = 2;
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-block",
        zIndex: 0,
        "& > *": {
          width: size + gap * 2,
          height: size + gap * 2,
          border: `${gap}px solid`,
          borderColor: gapColor,
        },
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          borderRadius: "50%",
          zIndex: -1,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          margin: `-${thickness}px`,
          background: color,
        },
      }}
    >
      <Avatar src={src} {...props} />
    </Box>
  );
}
