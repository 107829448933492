import React, { useState, useContext } from "react";
import {
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
} from "@mui/material";
import { ClubLocation } from "../components/ClubLocation";
import { Loading } from "../components/Loading";
import { AppContext } from "../context/AppContext";

export const ContactUs = () => {
  const { member } = useContext(AppContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [body, setBody] = useState("");
  const sendMessage = async () => {
    try {
      setLoading(true);
      setError(null);

      await fetch(
        "https://us-central1-manageyourclub-973b2.cloudfunctions.net/contactUs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: member?.email, body: body }),
        }
      );
      setBody("");
      setMessageSent(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <Paper elevation={12} sx={{ mt: 8, p: 4 }}>
      <Loading isLoading={loading} message="Sending message" />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        key="center"
        message="Message Sent"
        onClose={() => setMessageSent(false)}
        open={messageSent}
      />
      {error && <Box sx={{ color: "red" }}>{error}</Box>}
      <Box>
        <Typography gutterBottom variant="h4" component="h2">
          Have A Question?
        </Typography>
        <Typography gutterBottom variant="body1" component="p">
          We're here to help and answer any question you might have. We look
          forward to hearing from you.
        </Typography>
        <TextField
          value={body}
          onChange={(e) => setBody(e.target.value)}
          variant="outlined"
          label="Message"
          fullWidth
          multiline
          rows={4}
        />
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading || !body}
            onClick={() => sendMessage()}
          >
            Send Message
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 4 }}>
        <ClubLocation />
      </Box>
    </Paper>
  );
};
