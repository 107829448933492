import { Box, Typography } from "@mui/material";

export const ExpiredPass = ({ pass }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#DEDEDE",
        p: 2,
      }}
    >
      <Typography variant="overline">{pass.type} Pass</Typography>
      <Typography variant="subtitle1">Status: Expired</Typography>
      <Typography variant="subtitle2">
        Expired on: {pass.dateExpired.toDate().toLocaleDateString()}
      </Typography>
    </Box>
  );
};
