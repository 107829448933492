import React, { useState, useContext } from "react";
import { Paper, Box, Typography, Button, Snackbar } from "@mui/material";
import { ClubLocation } from "../components/ClubLocation";
import { Loading } from "../components/Loading";
import { AppContext } from "../context/AppContext";

export const DataDeletion = () => {
  const { member } = useContext(AppContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const sendMessage = async () => {
    try {
      setLoading(true);
      setError(null);

      await fetch(
        "https://us-central1-manageyourclub-973b2.cloudfunctions.net/contactUs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: member?.email,
            body: `Please delete my user data. My email is ${member?.email}`,
          }),
        }
      );
      setMessageSent(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <Paper elevation={12} sx={{ mt: 8, p: 4 }}>
      <Loading isLoading={loading} message="Sending Request" />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        key="center"
        message="Data Deletion Request Sent"
        onClose={() => setMessageSent(false)}
        open={messageSent}
      />
      {error && <Box sx={{ color: "red" }}>{error}</Box>}
      <Box>
        <Typography gutterBottom variant="h4" component="h2">
          Want to delete your data?
        </Typography>
        <Typography gutterBottom variant="body1" component="p">
          Submit this form to request your data to be deleted. We will send you
          a confirmation email.
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            onClick={() => sendMessage()}
          >
            Send Request
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 4 }}>
        <ClubLocation />
      </Box>
    </Paper>
  );
};
