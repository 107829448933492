import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Star from "@mui/icons-material/Star";
import { AppContext } from "../context/AppContext";
import { CartContext } from "../context/CartContext";
import { centsToCurrency } from "../services/firebase";

function PassItem({ name, description, amount, onSale, addToCart }) {
  return (
    <Grid item xs={12} sm={6} md={6}>
      <Card
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardHeader
          title={
            <Typography variant="h5" color={onSale ? "success" : "default"}>
              {name}
            </Typography>
          }
          avatar={
            onSale ? <Star color={onSale ? "success" : "default"} /> : null
          }
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="body1">{description}</Typography>
        </CardContent>
        <CardActions>
          <Button
            sx={{ fontSize: "1.25rem" }}
            variant="contained"
            fullWidth
            onClick={() => addToCart()}
          >
            {centsToCurrency(amount)}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

export const Passes = () => {
  const navigate = useNavigate();
  const { passTemplates } = useContext(AppContext);
  const { addToCart } = useContext(CartContext);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("Added to Cart");

  const handleClose = () => {
    setOpen(false);
  };

  const goToCheckout = () => {
    navigate("/checkout");
  };

  const handleAddToCart = (pass) => {
    addToCart(pass);
    setMessage(`${pass.name} added to cart`);
    setOpen(true);
  };

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        key="bottomcenter"
        message={message}
        onClose={handleClose}
        open={open}
        action={
          <Button color="warning" size="small" onClick={goToCheckout}>
            Checkout
          </Button>
        }
      />
      <Grid container spacing={2}>
        {passTemplates.map((pass) => (
          <PassItem
            key={pass.type}
            {...pass}
            addToCart={() => handleAddToCart(pass)}
          />
        ))}
      </Grid>
    </Box>
  );
};
